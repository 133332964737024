.ModalOpenUrl__Input {
  width: 100%;
  padding: .5rem;
  box-sizing: border-box;
  background-color: rgb(var(--grey-200));
  color: rgb(var(--black));
  border: none;
  margin-bottom: .5rem;
}
.ModalOpenUrl__Input:focus {
  outline: none;
}
.ModalOpenUrl__Button {
  background-color: rgb(var(--blue-500));
  color: rgb(var(--white-button));
  padding: .5rem 1rem;
  margin: 0;
  border: none;
  border-radius: .5rem;
  transition: all .3s ease;
  margin-top: 1rem;
}
.ModalOpenUrl__Button:hover {
  background-color: rgb(var(--red-500));
}