body {
  overflow: hidden;
  color: rgb(var(--grey-800));
}
.imageEditor {
    width: 100vw;
    height: 100vh;
    background: rgb(var(--grey-100));
    display: flex;
    position: relative;
}
.imageEditor_Canvas {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15), 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
  display: none;
  position: relative;
  z-index: 1;
  height: fit-content;
  width: fit-content;
}
.imageEditor__ChooseFile::after {
  display: block;
  content: 'Start by opening an image. 🖥';
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 200px);
  text-align: center;
  width: 400px;
  font-size: 1.5rem;
  line-height: 2rem;
  color:rgb(var(--grey-300));
}
.cursor_Hand {
  cursor: grab;
}
.cursor_zoomIn {
  cursor: zoom-in;
}
.cursor_zoomOut {
  cursor: zoom-out;
}
.imageEditor_Canvas canvas {
  display: block;
  image-rendering: optimizeSpeed;             /* Older versions of FF          */
  image-rendering: -moz-crisp-edges;          /* FF 6.0+                       */
  image-rendering: -webkit-optimize-contrast; /* Safari                        */
  image-rendering: -o-crisp-edges;            /* OS X & Windows Opera (12.02+) */
  image-rendering: pixelated;                 /* Awesome future-browsers       */
  -ms-interpolation-mode: nearest-neighbor;   /* IE                            */
}
.fixedLayer {
  position: absolute;
  top: 0;
  left: 0;
}
.toolsPane {
  position: fixed;
  left: 1.5rem;
  top: 100px;
  background-color: rgb(var(--white));
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
  z-index: 99;
  width: 55px;
}
.toolsPane__Head,
.optionsPane__Head {
  padding: .5rem .5rem;
  cursor: move;
  border-bottom: 1px solid rgb(var(--grey-200));
}
.toolsPane button {
  padding: 1rem;
  font-size: 1.1rem;
  width: 100%;
  display: block;
  border: none;
  background-color: rgb(var(--white));
  color: rgb(var(--grey-800));
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin: 0;
}
.toolsPane > * {
  border-bottom: 1px solid rgb(var(--grey-200));
}
.toolsPane > *:last-of-type {
  border-bottom: none;
}
.toolsPane button.toolsPane__Confirmation {
  background-color: rgb(var(--grey-100));
}
.toolsPane button:hover {
  background-color: rgb(var(--grey-100));
  color: rgb(var(--red-500));
}
.toolsPane__ColorSelectGroup {
  position: relative;
  width: 100%;
  height: 100%;
}
.toolsPane__ColorSelect {
  position: relative;
  display: block;
  width: 35px;
  height: 35px;
  background: none;
  border: 1px solid rgba(var(--grey-200));
  padding: 0;
  margin: .5rem auto;
  border-radius: .25rem;
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.toolsPane__ColorSelect:hover {
  border-color: rgba(var(--red-500));
}
.toolsPane__ColorSelectInput {
  height: 2px;
  width: 2px;
  opacity: 0;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  left: calc(50% - 2px);
  bottom: 0rem;
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.optionsPane {
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  background-color: rgb(var(--white));
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
  width: 600px;
  z-index: 99;
}
.optionsPane__pre {
  max-height: 150px;
  overflow: auto;
  margin: 0;
  padding: 0;
  font-size: .9rem;
  border-top: 1px dashed rgb(var(--grey-200));
  border-bottom: 1px dashed rgb(var(--grey-200));
}
.optionsPane__Description {
  color: rgb(var(--grey-500));
  font-size: .9rem;
  padding: 0 1rem;
}
.optionsPane__Head__Title {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: normal;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.optionsPane_Head__DragMe {
  font-size: .8rem;
  color: rgb(var(--grey-300));
  text-transform: uppercase;
  float: right;
  display: none;
}
.optionsPane__Head:hover .optionsPane_Head__DragMe {
  display: inline-block;
}
.optionsPane__Icon {
  margin-right: .5rem;
}
.optoinsPane__ClipboardButton {
  font-size: .8rem;
  background: none;
  border: none;
  cursor: pointer;
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  justify-content: center;
  align-items: center;
}
.optionsPane__ClipboardButton__Icon {
  font-size: 1.1rem;
  margin-right: .5rem;
}
.optoinsPane__ClipboardButton:hover {
  color: rgb(var(--red-500));
}
.optionsPane__ButtonRow {
  padding: 1rem;
}
.optionsPane__ButtonRow button {
  padding: .75rem 1rem;
  background-color: rgb(var(--white));
  color: rgb(var(--black));
  border-radius: .5rem;
  border: 1px solid rgb(var(--grey-200));
  font-size: .9rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.optionsPane__ButtonRow button:first-of-type {
  margin: 0;
}
.optionsPane__ButtonRow button.primary {
  background-color: rgb(var(--blue-500));
  padding: .75rem 1rem;
  color: rgb(var(--white-button));
  border-radius: .5rem;
  border: none;
  font-size: .9rem;
}
.optionsPane__ButtonRow button:hover,
.optionsPane__ButtonRow button.primary:hover {
  background-color: rgba(var(--red-500));
  color: rgb(var(--white-button));
}

.openSeaDragon {
  width: 100vw;
  height: 100vh;
  background: rgb(var(--grey-100));
  display: flex;
  position: relative;
}
.openSeaDragon__Canvas {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.openSeaDragon__Canvas div {
  border: none;
  outline: none;
}
.openSeaDragon__Canvas:focus {
  outline: none;
}
.openSeaDragon__Overlay {
  pointer-events: none;
}
.cursor .openSeaDragon__Overlay,
.erasing .openSeaDragon__Overlay {
  pointer-events: all;
}
.cursor .openSeaDragon__Overlay:hover,
.erasing .openSeaDragon__Overlay:hover {
  border-color: rgba(173, 90, 46) !important;
  background: rgba(173, 90, 46, 0.5) !important;
}
.erasing .openSeaDragon__Overlay:hover {
  cursor: pointer;
}
.cursor .openSeaDragon__Overlay:hover {
  cursor: move;
}
.Modal__TitleBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgb(var(--grey-200));
}
.Modal__Title {
  font-size: .9rem;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  padding: 0;
}
.Modal__CloseButton {
  background-color: transparent;
  border: none;
  padding: 0 .5rem;
  margin: 0;
  font-size: .9rem;
  color: rgb(var(--grey-800));
}
.Modal__CloseButton:hover {
  color: rgb(var(--red-500));
}
.Modal__Content {
  background-color: rgb(var(--white));
  padding: 1.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
  min-width: 400px;
  position: relative;
}