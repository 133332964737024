.Window {
  position: fixed;
  width: 300px;
  max-height: 300px;
  top: 10%;
  right: 5%;
  z-index: 99;
  background-color: rgb(var(--white));
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
  color: rgb(var(--grey-800));
}
.Content {
  flex: 1;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  border-radius: 0 0 .5rem .5rem;
  font-size: .8rem;
  position: relative;
}
.Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .8rem;
  padding: .5rem;
  border-bottom: 1px solid rgb(var(--grey-200));
  cursor: default;
}
.Title h4 {
  margin: 0;
  font-weight: normal;
}
.Title button {
  color: rgb(var(--grey-800));
  border-radius: .25rem;
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.Title button:hover {
  color: rgb(var(--white-button));
}