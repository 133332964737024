.Modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
}

.Modal__Content {
  margin: auto;
}