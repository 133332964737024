.ModalAbout {
  max-width: 800px;
  box-sizing: border-box;
}
.ModalTitle {
  display: flex;
  align-items: center;
}
.ModalAboutIcon {
  width: 64px;
  margin-right: 2rem;
}
.ModalAbout p {
  font-size: 1rem;
  line-height: 1.6rem;
  color: rgb(var(--grey-500));
}
.ModalAbout p a {
  color:rgb(var(--grey-500));
}
.ModalAbout p a:hover {
  color: rgb(var(--blue-500));
}
.ModalAbout p.Version {
  font-size: .8rem;
}
.Logo {
  display: block;
}
.LogoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 0 3rem;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem 0;
  height: 100%;
}