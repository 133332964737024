:root {
  --white: 255, 255, 255;
  --white-button: 255, 255, 255;
  --black: 0, 0, 0;
  --grey-100: 240, 240, 240;
  --grey-200: 234, 234, 234;
  --grey-300: 190, 190, 190;
  --grey-400: 150, 150, 150;
  --grey-500: 100, 100, 100;
  --red-500: 173, 90, 46;
  --blue-500: 14, 76, 173;
}
@media (prefers-color-scheme: dark) {
  :root {
    --white: 50, 50, 50;
    --white-button: 255, 255, 255;
    --black: 255, 255, 255;
    --grey-100: 30, 30, 30;
    --grey-200: 70, 70, 70;
    --grey-300: 110, 110, 110;
    --grey-400: 105, 105, 105;
    --grey-500: 155, 155, 155;
    --grey-800: 200, 200, 200;
    --red-500: 227, 92, 20;
    --blue-500: 27, 120, 226;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
