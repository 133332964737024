.Tooltip {
  position: relative;
}
.Tooltip * {
  user-select: none;
}
.TooltipBody {
  position: absolute;
  top: 0;
  left: 105%;
  border-radius: .5rem;
  background-color: rgb(var(--white));
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);
  width: 256px;
  overflow: hidden;
  height: auto;
}
.TooltipContent {
  padding: 1rem;
}
.Tooltip h2 {
  font-size: 1rem;
  font-weight: bold;
  color: rgb(var(--black));
  margin: 0 0 1rem;
  padding: 0;
}
.Tooltip p {
  font-size: .8rem;
  line-height: 1.4rem;
  color: rgb(var(--grey-500));
  margin: 0;
  padding: 0;
}